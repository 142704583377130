.manageLeagues {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 48px;
  gap: 32px;

  height: 948px;

  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}

.leagues {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.leagues > span {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.addNew {
  display: flex;
}
.addNew > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  width: 124px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.leagueTable {
  border-top: 1px solid #eaecf0;
  width: 100%;
  display: flex;
  gap: 24px;
}

.tableSize {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 50%;
  height: 258px;

  background: #ffffff;

  border: 1px solid #eaecf0;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.leagueName {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.leagueName > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.leagueName > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
  width: 66px;
  height: 40px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}



.season{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttons{
  display: flex;
  gap: 12px;
  margin-top: 30px;
}


.download{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  
  width: 100px;
  height: 40px;
  

  
  background: #FFFFFF;
 
  
  border: 1px solid #D0D5DD;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  
  
  color: #344054;
  
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.edit{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  
  width: 59px;
  height: 40px;
  

  
  background: #FFFFFF;
  
  
  border: 1px solid #D0D5DD;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  
  
  color: #344054;
  
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}


.detete{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  
  width: 76px;
  height: 40px;
  font-weight: 600;
font-size: 14px;
line-height: 20px;


color: #344054;

  
  background: #FFFFFF;

  border: 1px solid #D0D5DD;

  
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  
}