.manageDivison {
    width: 349.33px;
    height: 188px;
    display: flex;
    flex-direction: column;
    
    background: #ffffff;
  
    border: 1px solid #eaecf0;
  
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding-top: 34px;
    padding-left: 24px;
  }

  .coach {
    display: flex;
   
    align-items: center;
    gap: 22px;
  }
  .coach > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  
    color: #101828;
  }
  
  
  
  .addCoach {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
  
    color: #ffffff;
  
    background: #7f56d9;
  
    border: 1px solid #7f56d9;
  
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .coachImage {
    display: flex;
    margin-top: 34px;
    align-items: center;
  }
  .coachImage > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
  }

 




  
.goodisonText {
    display: flex;
  
    gap: 20px;
  }
  
  .goodisonText > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
  }
  
  .goodisonText > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  
    color: #039855;
  }
  
  
  
  