.createVenuesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createVenuesHeader > h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;

  color: #101828;
}

.buttonsStyle {
  display: flex;

  gap: 20px;
}

.create {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 112px;
  height: 40px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  width: 112px;
  height: 40px;
}




.createVenuesForm {
  display: flex;
  flex-direction: column;

}

.inputVenue {
  display: flex;
  flex-direction: column;
  gap: 5px;
 
  width: 39%;


}

.inputVenue > input {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.inputVenue > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.inputVenue > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.addressForm {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  
  margin-top: 16px;
}

.addressInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.addressInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.addressInput > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.addressInput > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.phoneForm {
  margin-top: 16px;
  display: flex;
  gap: 30px;


  
}

.createVenuesMiddle {
  display: flex;
  gap: 20px;
 
  width: 73.6%;
  margin-top: 6px;
}

.priority {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.priority > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 40px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;

  background: #ffffff;
  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.priority > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.createVenuesFooter {

  display: flex;
  flex-direction: column;
}

.checkboxInput {
  display: flex;
  width: 86%;  
  gap: 5px;
  margin-top: 15px;
}

.checkboxInput > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #101828;
}

.textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 667px;
  height: 112px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.venuesPlaying {
  margin-top: 26px;
  
  display: flex;
  width: 92%;
   justify-content: space-between;
  
    align-items: center;

}



.venuesPlaying>span{
    font-weight: 500;
font-size: 16px;
line-height: 24px;


color: #101828;

}

.venuesPlaying>button{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 16px;
gap: 8px;

width: 124px;
height: 44px;



background: #7F56D9;


border: 1px solid #7F56D9;
font-weight: 600;
font-size: 14px;
line-height: 20px;


color: #FFFFFF;

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

}


.surface{
    display: flex;

    margin-top: 20px;
}



.surface>input{
    display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;

width: 320px;
height: 44px;



background: #FFFFFF;


border: 1px solid #D0D5DD;


box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

}