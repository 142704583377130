.passwordUpdate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.updateIcon {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 64px;
}

.message > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.message > p {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;

  color: #667085;
}

.login {
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 268px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

