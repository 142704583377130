.table{
    padding: 24px;
    display: flex;
    flex-direction: column; 
    width: 349.33px;
    height: 539px; 
    background: #FFFFFF; 
    border: 1px solid #EAECF0;  
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding: 24px;
}

.vanueName{
    
    display: flex;
    justify-content: space-between;
}

.vanueName>span{
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #101828;
}

.vanueName>a{
width: 66px;
height: 40px;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
background: #7F56D9;
border: 1px solid #7F56D9;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
}

.address{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 10px;
}

.address>span{
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #101828;

}

.address>p{
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-decoration-line: underline;
color: #D6BBFB;

}

.teamName{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


.teamImage{
    display: flex;
    align-items: center;
}

.imageSize{
    width: 30px;
    height: 30px;
    border: 4px solid #ffffff;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #7f56d9;   
}

.imageLength{
    width: 28px;
    height: 28px;
    border: 4px solid #ffffff;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;    
    line-height: 18px;
    color: #7f56d9;
    position: relative;
    left: -50px;
    background-color: #f9f5ff;
}