.retryModalHeader {
  display: flex;
  flex-direction: column;

  height: 200px;
}

.retryModalIcon {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
}

.retryModalHeader > img {
  width: 19px;
  height: 19px;
}

.retryTitle {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
  margin-left: 10px;
}

.retryTitle > span {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.retryTitle > p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #667085;
}

.choiceGames {
  border: 1px solid #eaecf0;
  border-radius: 4px;
  height: 38px;
  width: 43%;
  display: flex;
  margin-top: 8px;
  border-radius: 8px;
}

.allGames {
  border-right: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: space-around;
}

.allGames > img {
  width: 16.67px;
  height: 16.67px;
}

.allGames > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.unScheduled {
  background: rgba(52, 64, 84, 0.7);
  color: white;
  display: flex;
  width: 67%;
  height: 36px;
  align-items: center;
  border-right: 1px solid #d0d5dd;
  justify-content: space-around;
  backdrop-filter: blur(8px);
  border-radius: 4px;
}

.unScheduled > img {
  width: 16.67px;
  height: 16.67px;
}

.retryModalMiddle {
  width: 100%;

  display: flex;
  margin-top: 20px;
  padding-left: 8px;
}

.checkBoxText {
  display: flex;
  flex-direction: column;
}

.avoid {
  display: flex;
  gap: 16px;
}

.avoid > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.balacne {
  display: flex;
  gap: 16px;
}

.balacne > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.enforce {
  margin-left: 16px;
}

.retryModalFooter {
  display: flex;

  margin-top: 32px;
  gap: 39px;
  display: flex;
}

.gamesDays {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;

  width: 45%;

  gap: 53px;
}

.maxGames {
  border: 1px solid #d0d5dd;
  width: 333px;
  height: 40px;
  margin-top: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #667085;
}

.week {
  display: flex;
  justify-content: space-between;

  flex-direction: column;
}

.gameCancle {
}

.gameCancle > button {
  width: 330.5px;
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  background: #ffffff;
}

.submitRetry {
  margin-top: 0px;
}

.submitRetry > button {
  width: 335.5px;
  height: 44px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}
