.createSeason {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icon {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createSeason > h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.createSeason > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.createSeason > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  color: #039855;
}

.createSeason > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.buttonsCreate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.continue {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  font-weight: 600;
font-size: 16px;
line-height: 24px;

color: #FFFFFF;


  width: 665px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 665px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
