.addHeader {
  display: flex;
  flex-direction: column;
}

.addTime {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addIcon {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTime > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.addTime > p {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.weekly {
  display: flex;
  margin-top: 20px;
  gap: 21px;
}

.round > label {
  display: flex;
}

.weeklyText {
  color: #131313;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
}

.label > input[type="checkbox"] {
  display: none;
}

.label > input[type="checkbox"] + span:before {
  width: 13px;
  height: 13px;
  border: 1px solid #d0d5dd;
  content: "";
  display: inline-block;
  margin: 2px 8px 0 0;
  padding: 0;
  vertical-align: top;
  border-radius: 50%;
}

.label > input[type="checkbox"]:checked + span:before {
  background: #1460c8;
  border: 1px solid #1460c8;
  box-shadow: inset 0px 0px 0px 1px #fff;
  color: #333;
  content: "";
  text-align: center;
  border-radius: 50%;
}

.label > input[type="checkbox"]:focus + span::before {
  outline: 0;
}

.weeklyText {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #101828;
}

.addMiddle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 1px;
  gap: 20px;
}

.selectDays {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.days {
  display: flex;

  gap: 20px;
}

.days > div {
  width: 7.5%;
  display: flex;
  justify-content: space-between;
}

.constraint {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.addFooter {
  margin-top: 20px;
  display: flex;
  gap: 20px;

  flex-wrap: wrap;
}

.NoPlayText {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #101828;
}

.apply {
  margin-top: 32px; 
  display: flex;
  justify-content: center;
}

.applyText {
  display: flex;
  gap: 8px;
}

.applyText > label {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #101828;
}
