.schedulesMain {
  width: 82%;
  display: flex;
  flex-direction: column;
  background: blue;
  padding: 50px 32px 50px;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
  height: 940px;
}

.schedulesHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 99%;
}
.schedulesText {
  color: #101828;
  font-size: 30px;
  line-height: 38px;
  margin-left: 12px;
  display: flex;

 
  justify-content: center;
}

.schedulesUpload {
  display: flex;
  gap: 16px;
  align-items: center;
    margin-left: auto;

 
}
.downloadFIle > input[type="file"] {
  display: none;
}

.downloadFIle > label {
  width: 236px;
  height: 40px;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px 22px;
}

.downloadFIle {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.createSchedules {
  display: flex;
}

.createSchedules > button {
  width: 173px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  background: #7f56d9;

  border: 1px solid #7f56d9;
  color: #ffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.createSchedules > button > span {
  padding-left: 10px;
}

.shedulesName {
  border: 1px solid white;
  display: flex;
  gap: 24px;
  margin-top: 36px;
  gap: 24px;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 24px;
 
}

.schedulesDescription {
  width: 349.33px;
  height: 276px;
  padding-left: 24px;
  background: #ffffff;

  border: 1px solid #eaecf0;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.schedulesImage {
  display: flex;

  justify-content: space-between;
  margin-top: 24px;
}
.schedulesImage > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}
.schedulesImage > img {
  padding-right: 10px;
}

.resultes {
  margin-top: 24px;
  color: #101828;
  font-size: 16px;
  line-height: 24px;
}

.divisionName {
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.scheduled {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-top: 8px;
}

.percent {
  display: flex;
  margin-top: 8px;
  gap: 8px;
   
    align-items: center;
}

.created {
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: #101828;
}

.scheduledData {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;

  color: #d6bbfb;
}

.shedulesLink{
  margin-right: 33px;
}