.adminModalHeader {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;

  background: #d1fadf;

  border: 8px solid #ecfdf3;
  border-radius: 28px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.headerTitle > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.headerTitle > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}
.adminModalMiddle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  width: 40%;
}

.chooisCheckbox {
  gap: 19px;
  display: flex;
}

.chooisCheckbox > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.chooisDivision {
  display: flex;
  gap: 19px;
  margin-top: 19px;

  width: 120%;
}

.chooisDivision > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.adminModalFooter {
 
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  gap: 12px;
  width: 500px;
}
.adminCancleButton {
  width: 170px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.adminCreateButton {
  width: 170px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

