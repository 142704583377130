.company-menu {
  position: relative;
  max-height: 100vh;
}
.company-menu-fixed {
  position: fixed;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #101828;
  width: 280px;
    height: 960px;
}
.header-company-name {
  display: flex;
  align-items: center;
  height: 100px;
  gap: 12px;
}
.text-company-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.company-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 14%;
  width: 100%;
  margin-top: 24px;
}

.select-style {
  height: 44px;
  appearance: none;
  -webkit-appearance: none;
  background: #344054;
  width: 250px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 14px;
  color: #ffffff;

  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%),
    radial-gradient(transparent 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

@media screen and (max-width: 920px) {
}
/* select.round:focus {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - .5em) .5em;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
} */

.company-menu-nav-bar {
  margin-top: 24px;
  width: 96%;
  height: 600px;
}

.container-nav-bar {
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f2f4f7;
}
.container-nav-bar.active {
  background: #344054;
  border-radius: 6px;
  width: 230px;
}
.container-nav-bar > li a {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 2px;
  border-radius: 6px;
  transition: background 0.3s;
  color: inherit;
  text-decoration: none;
}

.container-nav-bar > li:hover {
  background: #344054;
  border-radius: 6px;
  width: 230px;
}

.side-bar-icon {
  width: 36px;
  display: flex;
  align-items: center;
}

.company-settings {
  display: flex;
  gap: 10px;
  margin-bottom: 47px;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
}

.company-settings:hover, .company-settings.active {
  background: #344054;
  border-radius: 6px;
  width: 230px;
}

.bottom-border-company {
  border: 1px solid #475467;
  width: 100%;
  position: relative;
  left: -7px;
}

.company-settings > span {
  padding-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.companu-user {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.info-users {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.info-users > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
}

.info-users > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f2f4f7;
}

.exit {
  display: flex;
  align-self: center;
}

.li-size-bar {
  padding-left: 15px;
}



.info-users>p{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #F2F4F7;
}

.settings{
  text-decoration: none;
  display: flex;
  gap: 10px;
}
