.calendarMain {
  width: 70%;
  height: 1016px;
  border: 1px solid;
  display: flex;
  flex-direction: column;

  padding: 54px 62px 48px;
  gap: 32px;

  height: 1016px;

  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}

.calendarHeader {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 53.5px;
}
.calendarText {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.calendarHeaderButtons {
  display: flex;
  justify-content: space-between;
  width: 63%;
}

.calendarMoreFiltersButton {
  width: 138px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.calendarMoreFiltersButton > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 15px;
  color: #344054;
}
.calendarResetButton {
  width: 117px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.selectAllButton {
  width: 94px;
  height: 40px;

  background: #d6bbfb;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
}

.calendarMiddle {


  display: flex;
}

.calendarChoose {
  
  display: flex;
  flex-direction: column;
  width: 47%;
  
}

.calendarChoose > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
 
  color: #344054;
}

.calendarChoose > select {
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-top: 6px;
}
.calendarSurfaces{
    display: flex;
    flex-direction: column;
    margin-top: 16px;

}

.calendarSurfaces>span{
    font-weight: 500;
  font-size: 14px;
  line-height: 20px;
 
  color: #344054;
}


.calendarSurfaces > select {
    width: 320px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    margin-top: 6px;
  }
  