.forgotPassword {
  border: 1px solid;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.forgotPasswordHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.forgotPasswordHeader > h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.forgotPasswordHeader > p {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  color: #667085;
}

.forgotPasswordForm {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.forgotPasswordForm > input {
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.send {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.sendButton {
  width: 268px;
  height: 44px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.backButton {
  padding: 10px 16px;

  width: 268px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.backButton > a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.dont {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;

  color: #667085;
}
.signLink {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;

  color: #d6bbfb;
}
