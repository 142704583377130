.seasonTeam {
  display: flex;
  flex-direction: column;
}

.textTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.textTitle > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #039855;
}

.textTitle > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.textTitle > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.divisonNameTable {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 16px;
  gap: 4px;
  width: 595px;
  height: 110px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.divisonTableTitle {
  display: flex;

  justify-content: space-between;
}

.divisionColor {
  display: flex;
  align-items: center;
  gap: 10px;
}

.divisionColor > div {
  width: 32px;
  height: 32px;

  background: #b4ff99;

  border: 4px solid #f9f5ff;
  border-radius: 28px;
}

.divisionColor > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.abbrevitation {
  display: flex;

  gap: 50px;
  justify-content: center;
  align-items: center;
}

.abbrevitation > div {
  border-radius: 50px;
  border: 1px solid #d0d5dd;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abbrevitation > div > span {
  color: #d0d5dd;
  font-size: 18px;
}

.divisionInput {
  display: flex;
  padding-left: 37px;
  gap: 32px;
}

.teamDivision {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
  width: 320px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.numberDivison {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 91px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}


.seasonName{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 16px 16px 59px;
gap: 4px;
width: 595px;
height: 236px;
background: #FFFFFF;
border: 1px solid #EAECF0;
border-radius: 8px;
}
.seasonNameTitle{
  display: flex;
  
  width: 85%;
  justify-content: space-between;
}

.seasonNameTitle>span{
  font-weight: 500;
font-size: 14px;
line-height: 20px;


color: #344054;

}

.container{
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.teamInputs{
  display: flex;
  gap: 28px;

}

.footerDivison{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 16px;
gap: 4px;

width: 595px;
height: 98px;



background: #FFFFFF;


border: 1px solid #EAECF0;
border-radius: 8px;
}

.abbrevitationPlus{
  display: flex;
  gap: 60px;
}


.abbrevitationPlus>span{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  
}

.abbrevitationPlus>div{
  border:1px solid #D0D5DD;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}


.abbrevitationPlus>div>span{
color: #D0D5DD;

}

.buttonsTable{
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}