.aboutSchedulesHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 52px 32px 48px;
  gap: 32px;
  width: 72%;
  height: 948px;

  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}

.scheduleTextName {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.scheduleSettings {
  height: 46px;
  border: 2px solid #eaecf0;
  border-radius: 40px 0px 0px 40px;
  align-items: center;
  display: flex;
  font-weight: 500;

  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  justify-content: space-around;
}

.scheduleSettings > div {
  display: flex;
  gap: 9px;
  align-items: center;
}

.schedulesDivision {
  width: 100%;
  height: 220px;
  border: 1px solid #eaecf0;
  display: flex;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.schedulesDivisionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 50%;
  margin-left: 24px;
}

.divisonTextName {
  display: flex;
  flex-direction: column;

  height: 71px;
  justify-content: space-between;
}

.divisonTextName > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

.divisionDate {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;

  width: 31%;
}

.divisionDate > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.divisionGame {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-top: 8px;
  align-items: flex-start;
}

.divisionGame > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.divisionGame > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
  margin-top: 8px;
}

.scheduleChoise {
  display: flex;
  gap: 12px;
}

.moreFilters {
  border: 1px solid #d6bbfb;
  background: white;
  width: 138px;
  height: 40px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #344054;
  line-height: 20px;
}
.moreFilters:hover {
  background-color: #7f56d9;
  color: white;
}

.choiceButtons {
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #344054;
  line-height: 20px;
}

.choiceButtons:hover {
  background-color: #7f56d9;
  color: white;
}

.scheduleSelectDivision {
  display: flex;
  width: 80%;
  gap: 24px;
}

.choiceSelect {
  display: flex;
  flex-direction: column;

  gap: 6px;
}

.choiceSelect > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.choiceSelect > select {
  width: 320px;
  height: 41px;
  appearance: none;
  -webkit-appearance: none;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 14px;
  color: #667085;
  border: 2px solid #eaecf0;
  background-image: linear-gradient(45deg, transparent 50%, #667085 50%),
    linear-gradient(135deg, #667085 50%, transparent 50%),
    radial-gradient(transparent 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.schedulesCheckBox {
  width: 96%;
  display: flex;
  gap: 16px;
  justify-content: space-evenly;
}

.schedulesCheckBox > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #101828;
}

.aboutSchedulesFooter {
  width: 93%;
  display: flex;
  flex-direction: column;
}
.aboutSchedulesFooterHeader {
  display: flex;
}
.title {
  width: 116px;
  height: 44px;

  display: flex;

  align-items: center;
}

.title > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 0px;
  color: #667085;
}

.titleWitchIcon {
  width: 14.2%;
  height: 44px;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleWitchIcon > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.vertical {
  border: 1px solid;
  display: flex;
  flex-direction: column;
}

.imageBottom {
  position: relative;
  bottom: 4px;
}

.imageTop {
  position: relative;
  top: 5px;
  right: 33px;
}

.aboutSchedulesBottom {
  border: 1px solid #eaecf0;
  width: 100%;
  display: flex;
}
.numberBottom {
  border-right: 2px solid #eaecf0;
  width: 11.2%;
  height: 72px;
  display: flex;
  align-items: center;
}

.numberBottom > span {
  margin-left: 24px;
}

.aboutScroll {
  background: #d6bbfb;
  border-radius: 8px;

  width: 532px;
  height: 4px;

  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

.hrefSettings{
  color: inherit;
    text-decoration: none;
}