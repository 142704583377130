.venues {
  width: 1178px;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
  height: 948px;
  overflow-x: hidden; 
  overflow-y: auto;
  padding: 50px 32px 50px;

  margin-top: 12px;
}

.venueHeader {
  display: flex;

  justify-content: space-between;
}

.upload {
  width: 226px;
  height: 44px;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.venueTable {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 1100px;
}
.venueHeader > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.viewEdit {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 66px;
  height: 40px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  color: #ffffff;
}
