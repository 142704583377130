.selectTitle{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.textTittle{
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.textTittle>h1{
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #101828;

}


.textTittle>span{
    font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #667085;

}


.textTittle>p{
font-weight: 600;
font-size: 16px;
line-height: 28px;
color: #039855;

}

.selectDivisonForm{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
}


.selectDivisonForm>div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.selectDivisonForm>div>input{
display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;
width: 320px;
height: 40px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

}

.divisonFormButton{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    height: 105px;
}