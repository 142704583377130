.deleteModalHeader {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.deleteIconSize {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;

  background: #d1fadf;

  border: 8px solid #ecfdf3;
  border-radius: 28px;
}

.deleteText {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.deleteButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
}

.deleteButtonSize {
  width: 312px;
  height: 44px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.cancleButtonSize {
  width: 312px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}
