.editModalHeader {
  
  display: flex;
  flex-direction: column;
}

.editTravel {
  
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.done {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editTravel > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.weekly {

  display: flex;
  margin-top: 20px;
  gap: 148px;
}

.weekly > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #667085;
}


.editModalMiddle{
    margin-top: 20px;
    display: flex;
    
    flex-wrap: wrap;
    gap: 22px;
}

.maxDistance{
    display: flex;
    flex-direction: column;
    gap: 6px;
}


.maxDistance>input{
width: 320px;
height: 40px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
padding: 10px 14px;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}


.editModalFooter{
   
    margin-top: 32px;
    display: flex;
    justify-content: center;
}


.apply{
    display: flex;
    gap: 8px;
}

.apply>span{
    font-weight: 400;
font-size: 18px;
line-height: 20px;


color: #101828;

}