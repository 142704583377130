.createNewPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.createNewPassText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.createNewPassText > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;

  color: #101828;
}

.createNewPassText > p {
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  color: #667085;
}

.createForm {
  margin-top: 64px;
}

.createInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.createInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 44px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.createInput > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.resetButton {
  margin-top: 64px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.resetButton > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 268px;
  height: 44px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
