.scrollbar {
  display: flex;
  height: 4px;
  width: 387px;

  background: #d6bbfb;
  border-radius: 8px;
  background: #f5f5f5;
  overflow-x: scroll;

  overflow-y: hidden;
}



#style-1::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #d6bbfb;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #d6bbfb;
}

#style-1::-webkit-scrollbar {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d6bbfb;
}


