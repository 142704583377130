.table {
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.header {
  padding: 13px 0 13px 24px;
  display: grid;
  align-items: center;
  grid-gap: 24px;
  grid-template-columns: 200px repeat(3, minmax(100px, 150px)) 1fr;
}

.item {
  padding: 27px 24px;
  display: grid;
  align-items: center;
  grid-gap: 24px;
  grid-template-columns: 200px repeat(3, minmax(100px, 150px)) 1fr;
  border-top: 1px solid #eaecf0;
}
.check {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #f9f5ff;
  border: 1px solid #7f56d9;
  border-radius: 6px;
  position: relative;
}

.check[variant="minus"]:checked:before {
  content: "\2212";
}

.check:checked:before {
  display: block;
  width: 20px;
  height: 20px;
  content: "\2714";
  font-weight: bold;
  color: #7f56d9;
  line-height: 20px;
  text-align: center;
}

.item:nth-child(odd) {
  background-color: #f9fafb;
}

.team {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.round {
  width: 40px;
  height: 40px;
  background-color: rgb(212, 101, 212);
  border-radius: 50%;
}
.steamName{
  font-size: 14px;
line-height: 20px;
color:#101828;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
}
.desctionTeamName{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;


color: #667085;

}
.travel {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
}

.travel button {
  background: none;
  border: none;
}

.travel button:last-child {
  margin-left: 28px;
}

.accountBox {
  display: flex;
  align-items: center;

  min-width: 335px;
}

.accountImage {
  width: 40px;
  height: 40px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  /* font-size: 12px; */
  line-height: 18px;
  color: #7f56d9;
}

.end {
  width: 40px;
  height: 40px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  /* font-size: 12px; */
  line-height: 18px;
  color: #7f56d9;
  position: relative;
  left: -30px;
  background-color: #f9f5ff;
}


.travel{
  padding-left: 24px;
}