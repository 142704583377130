.viewPage {
  width: 1160px;
  height: 948px;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
  padding: 32px 32px 48px;
}

.viewPageHeader {

  display: flex;
  margin-top: 20px;
  gap: 36px;
  flex-direction: column;
}

.viewPageHeader > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.venueTable {
  padding: 24px;
  width: 1096px;
  height: 498px;
  display: flex;
  flex-direction: column;

  background: #ffffff;

  border: 1px solid #eaecf0;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.vanueName {
  display: flex;
  justify-content: space-between;
}

.vanueName > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.vanueName > a {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 44px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  gap: 8px;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.address > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.address > p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #d6bbfb;
}






.viewFooter {

  display: flex;
  margin-top: 24px;
  gap: 24px;
}

.note1 {
  display: flex;
  flex-direction: column;
  width: 536px;
  height: 188px;
  gap: 34px;
  padding: 24px;
  background: #ffffff;

  border: 1px solid #eaecf0;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.noteText {
  display: flex;

  justify-content: space-between;
}

.noteText > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.noteText > button {
  width: 124px;
  height: 44px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  gap: 13px;
}

.goodison {
  display: flex;
  width: 50%;
 
  gap: 21px;
}

.goodison > p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Text color */

  color: #101828;
}

.goodison > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #039855;
}
