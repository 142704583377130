.editPage {
  width: 66.4%;
  height: 948px;
  display: flex;
  background-color: white;
  padding: 32px;
  flex-direction: column;
}

.editPageHeader {
  display: flex; 
 justify-content: space-between;
}

.editPageHeader > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.editPageHeader > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 116px;
  height: 40px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}


.editPagesForm{
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
 
}


.name{
  display: flex;
  flex-direction: column;
  gap: 6px;

  width: 90%;


  
  
}

.input{
  display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;
width: 320px;
height: 44px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}

.name>div{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.addressForm{
  display: flex;
  gap: 24px;
  margin-top: 20px;
}



.addressInput{
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.addressInput>input{
  display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;
width: 320px;
height: 44px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}


.span{
  
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
 
  
  color: #667085;
  
}


.editPagesMiddle{
display: flex;
margin-top: 20px;
gap: 25px;
}

.priority{
display: flex;
flex-direction: column;
gap: 5px;
}

.span{
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #344054;

}

.input{
display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;
width: 320px;
height: 40px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;
}

.venuesNotes{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.textarea{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 667px;
  height: 112px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.editPagesFooter{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}


.checkboxInput{
  display: flex;
  gap: 10px;

}

.checkboxInput>span{
font-weight: 400;
font-size: 18px;
line-height: 20px;
color: #101828;

}

.venuesButton{
  display: flex;
  
  width: 90%;
  align-items: center;
  justify-content: space-between;
}

.venuesButton>button{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 16px;
gap: 8px;
width: 124px;
height: 44px;
background: #7F56D9;
border: 1px solid #7F56D9;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;

}