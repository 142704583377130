.divisionMain {
  width: 83.2%;
  height: 1016px;
  background-color: white;
  padding-left: 60px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

.divisionHeader {
  margin-top: 17px;
}

.divisionHeader > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.divisionMidle {
  margin-top: 36px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}


.settingsDivision {
  width: 349.33px;
  height: 400px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding-top: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}

.divisionTitle {
  display: flex;

  gap: 98px;
}

.divisionTitle > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-top: 15px;
}
.buttonEdit {
  width: 91px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
}

.manage {
  display: flex;
  gap: 103px;
  margin-top: 34px;
}
.teamNameImage {
  margin-top: 8px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.buttonManage {
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #d6bbfb;
  background: #ffffff;
}

.infoImage {
  display: flex;
  align-items: center;
}

.divisionAccountImage {
  width: 30px;
  height: 30px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #7f56d9;
}

.imageLength {
  width: 28px;
  height: 28px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  /* font-size: 12px; */
  line-height: 18px;
  color: #7f56d9;
  position: relative;
  left: -50px;
  background-color: #f9f5ff;
}

.schedulesCreate {
  margin-top: 24px;
  display: flex;
  gap: 85px;
}

.schedulesCreate > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

.schedulesCreate > button {
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: underline;

  background-color: #ffffff;

  color: #d6bbfb;
}


.scheduleNames{
    margin-top: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
}


