.editName {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.editModalHeader {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.editModalIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
}

.editNameText {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.editModalMiddle {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.editModalMiddle > input {
  width: 326px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 8px;
}

::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 14px;
  color: #667085;
}

.editNameModalFooter {
  margin-top: 32px;
  display: flex;

  gap: 12px;
  flex-direction: column;
}

.editNameSaveButton {
  width: 326px;
  height: 44px;

  background: #7f56d9;

  box-sizing: border-box;
  border: 1px solid #7f56d9;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.editNameCancleButton {
  width: 326px;
  height: 44px;

  box-sizing: border-box;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
