.divisionModalEditHeader {
  display: flex;
  flex-direction: column;
}

.divisonDoneImage {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1fadf;

  border: 8px solid #ecfdf3;
  border-radius: 28px;
}

.editText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}
.editText > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.editText > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.divisionModalEditMiddle {
  display: flex;

  margin-top: 8px;
  flex-direction: column;
}

.divisionNumber {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.divisionNumber > input {
  width: 320px;
  height: 40px;

  background: #ffffff;
  padding-left: 14px;
  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.divisionNumber > label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.divisionAbbreviation {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  gap: 6px;
}

.divisionAbbreviation > input {
  width: 320px;
  height: 40px;

  background: #ffffff;
  padding-left: 14px;
  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.divisionAbbreviation > label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.costomCode {
  display: flex;

  gap: 22px;
}

.chooseColor {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 20px;
}

.chooseColor {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.colors {
  display: flex;
  gap: 10px;
}

.divisionModalEditFooter {
  display: flex;
  margin-top: 26px;
  flex-direction: column;
}

.deleteDivision {
  display: flex;
  gap: 20px;
}

.deleteDivisionButton {
  width: 166px;
  height: 44px;

  background: #d6bbfb;
  display: flex;
  border: 1px solid #d6bbfb;
  justify-content: center;
  align-items: center;
  gap: 9px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.deleteDivisionButton > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.UploadDivisionButton {
  width: 234px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;

  background: #d6bbfb;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.UploadDivisionButton > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.chooseButton {
  margin-top: 32px;
  display: flex;
  gap: 12px;
}

.sizeCancle {
  width: 325.5px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.sizeSave {
  width: 325.5px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  background: #7f56d9;

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.color {
  height: 16px;
  width: 16px;
  border-radius: 3px;
}

.activeColor {
  transform: scale(1.3);
  transition: all .8s ease-in-out;
  box-shadow: 0 5px 5px #6c6c6c;
}