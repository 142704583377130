.settings {
  padding: 32px 32px 48px;
  width: 1160px;
  height: 948px;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}

.settingsHeader {
  display: flex;
  flex-direction: column;
}

.settingsHeader > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}
.settingsPage {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 24px;
  border-bottom: 1px solid grey;
}

.settingsPage > a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}


.settingsPageNavigation{
  color:blue;
  padding-bottom: 20px;
  border-bottom: 1px solid blue;
}