.createLeague {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.plusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  background: #d1fadf;

  border: 8px solid #ecfdf3;
  border-radius: 28px;
}
.createLeague > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.createForm {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
}

.leagueInput {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.leagueInput > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.leagueInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 320px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.leagueInput > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.upload {
  margin-top: 32px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.upload > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 234px;
  height: 44px;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
}

.buttonsLeague {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.continue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 665px;
  height: 44px;

  font-size: 16px;
  line-height: 24px;
  
  
  color: #FFFFFF;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;

  width: 665px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
