.login {
  display: flex;

  width: 80%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;

  width: 60%;
}

.loginInputs {
  display: flex;

  flex-direction: column;
}

.welcome > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.loginInputs {
  margin-top: 64px;
  gap: 8px;
}

.loginInputs > input {
  padding: 10px 14px;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.remember {
  margin-top: 8px;
  display: flex;
  gap: 20px;
}

.remember > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  color: #667085;
}

.loginButton {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top:64px ;
}

.loginButton > button {
  width: 268px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}


.signUpLink{
    text-decoration: none;
    color: #D6BBFB;
    font-weight: 500;
font-size: 18px;
line-height: 38px;
}



.loginButton>span{
font-weight: 500;
font-size: 18px;
line-height: 38px;
color: #667085;

}


.forgot{
    font-weight: 500;
font-size: 14px;
line-height: 38px;
text-decoration: none;

color: #D6BBFB;

}