.myDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 48px;
  gap: 32px;
  border-radius: 40px 0px 0px 40px;
}

.detailsPerson {
  display: flex;
  justify-content: space-between;

  width: 100%;

  align-items: center;
}

.personalText {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.personalText > span {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.personalText > p {
  font-weight: 400;
  font-size: 14px;

  color: #667085;
}

.detailsButton {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
  width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  width: 65px;
  height: 40px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.detailsForm {
  border-top: 2px solid #eaecf0;
  width: 100%;
  margin-top: 20px;
  display: flex;

  flex-direction: column;
}

.name {
  display: flex;
  margin-top: 20px;
  gap: 150px;
  align-items: center;
}
.name > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.nameForm {
  display: flex;
  gap: 25px;
}

.nameForm > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 244px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.nameForm > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

.email {
  display: flex;
  border-top: 2px solid #eaecf0;
  margin-top: 20px;
  gap: 96px;
  padding-top: 20px;
  align-items: center;
}

.email > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.message {
  position: relative;
}
.message > img {
  position: absolute;
  top: 30%;
  left: 15px;
}
.message > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px;

  width: 512px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  border-radius: 8px;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.zone {
  display: flex;
  border-top: 2px solid #eaecf0;
  margin-top: 20px;
  gap: 125px;
  padding-top: 20px;
  align-items: center;
}

.zone > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.timezone > select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 512px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.pacific {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.detailsCalendar {
  display: flex;
  border-top: 2px solid #eaecf0;
  
  width: 100%;
  gap: 125px;
  padding-top: 20px;
  align-items: center;
}

.detailsCalendar>span{
    font-weight: 500;
font-size: 14px;
line-height: 20px;


color: #667085;

}

.firstDay {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.firstDay>span{
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #667085;

}

.firstDay>select{
    display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;

width: 240px;
height: 44px;



background: #FFFFFF;


border: 1px solid #D0D5DD;
font-weight: 500;
font-size: 16px;
line-height: 24px;


color: #101828;

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

}


.checkbox{
    
    display: flex;
    gap: 20px;
    margin-top: 5px;
}

.checkboxDay{
    display: flex;
    gap: 5px;
}

.checkboxDay>span{
    font-weight: 400;
font-size: 18px;
line-height: 20px;


color: #101828;

}