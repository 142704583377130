.gameModalPagesHeader {
  display: flex;
  flex-direction: column;

  gap: 36px;
  margin-top: 24px;
}

.teamBalanceText {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.printBalance {
  display: flex;
  gap: 27px;
}

.printBalancButton {
  width: 122px;
  height: 40px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.printDownload {
  width: 133px;
  height: 40px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  background: #ffffff;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameModalTable {
  margin-top: 66.5px;
  display: flex;
  flex-direction: column;
}
.gameModalTeamNumber {
  display: flex;
  width: 86%;
}

.teamNameStyle {
  width: 150px;
  height: 44px;
  background: #ffffff;
  border-bottom: 1px solid #eaecf0;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
}

.teamNameStyle > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  margin-left: 24px;
}

.gameTableColumn {
  display: flex;
}

.score {
  display: flex;
  width: 150px;
  height: 72px;
  border: 1px solid #d0d5dd;
  align-items: center;
}

.score > span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 24px;
  color: #101828;
}

.gameTableScroll {
  margin-top: 19px;
  border-bottom: 1px solid #d0d5dd;
}

.gameTableScroll > div {
  position: relative;
  bottom: 2px;
}

.tablePage {
  display: flex;

  width: 86%;
  margin-top: 20px;
}

.tablePage > div {
  width: 100%;
}

.gameModalFooter {
  border: 1px solid #eaecf0;
  margin-top: 64px;
  display: flex;
  width: 85%;

  flex-direction: column;
  
  
}

.rowTable {
  display: flex;
  border: 1px solid;
 
}

.vectorTop {
  position: relative;
  left: 22px;
  
  width: 8px;
  height: 8px;

}
.vectorBottom {
  position: relative;
  bottom: 5px;
  left: 20px;
  width: 8px;
  height: 8px;
}


.team {
  display: flex;
  width: 150px;
  height: 44px;
  align-items: center;
 
    
}

.team > span {
  margin-left: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #667085;
}


.teamPlace{
  border:1px solid;
  display: flex;

  
}

.coloumn{
  width: 150px;
height: 72px;
display: flex;
border:1px solid;



background: #F9FAFB;


border-bottom: 1px solid #EAECF0;
align-items: center;
}

.coloumn>span{
  margin-left: 24px;
}