.admin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 48px;
  gap: 32px;

  width: 100%;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}

.admin-header {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  justify-content: space-between;
}

.size-create-button {
  align-self: center;
}

.welcome-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 37px;
}

.welocome-text-size {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.lorem-text {
  font-size: 16px;
  line-height: 38px;

  color: #667085;
}

.admin-button-size {
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 40px;

  background: #7f56d9;
  color: white;
  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.admin-divisions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
  margin-bottom: 34px;
  grid-gap: 24px;
}

.total-divisions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.text-total-divisions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.text-line {
  display: inline-flex;
  align-self: flex-end;
  justify-self: flex-end;
}

.text-number {
  display: block;
  margin-bottom: 24px;
  font-size: 36px;
}

.text-box {
  display: flex;
  align-items: center;
  gap: 7px;
}

.divisions-footer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}
.pracent {
  color: #027a48;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text-pracent {
  color: #b42318;
}

.admin-filters {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  justify-content: space-between;
}

.filter-button-size {
  width: 136px;
  height: 40px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
}
.filter-button-size > img {
  padding-left: 10px;
}
.button-more-filters {
  width: 138px;
  height: 40px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  background: #ffffff;
  color: #344054;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.filters-button {
  display: flex;
  align-items: center;

  gap: 20px;
}

.button-more-filters > span {
  padding-left: 10px;
}

.search > input {
  width: 440px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  padding-left: 43px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 16px;
}
.search > input::placeholder {
  padding-left: 10px;
}
.search {
  position: relative;
  display: flex;
  align-items: center;
}
.search-icon {
  position: absolute;
  padding-left: 16px;
  top: 32px;
}

.next-previus-page {
  display: flex;
  padding-left: 20px;
  margin-top: 8px;
  justify-content: space-between;
}

.button-page {
  display: flex;
  gap: 16px;
}

.button-priveus {
  width: 86px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  box-sizing: border-box;
}

.button-next {
  width: 60px;
  height: 36px;
  box-sizing: border-box;
  background: #ffffff;
  color: #344054;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.list-page {
  display: flex;
  align-self: center;
  width: 6%;
}

.list-page > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}
