.addCoach {
  display: flex;
  flex-direction: column;
}
.green {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chooseCoach {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chooseText {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.addCoachModalMidle {
  margin-top: 20px;

  display: flex;
}

.eligible {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.coachSelect {
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
}

.team {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 19px;
}

.olivia {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.createMewCoach {
  margin-top: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.createCoach {
  width: 179px;
  height: 44px;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.addCoachModalFooter {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.addCoachButtonSave {
  width: 665px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.addCoachButtonCancel {
  width: 665px;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344054;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
