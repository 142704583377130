.gameList {
  width: 70%;
  height: 1016px;
  padding: 54px 62px 48px;
  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
  display: flex;
  flex-direction: column;
}

.gameListHeader {
  display: flex;
  flex-direction: column;
}

.gameListHeader > span {
  color: #101828;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
}

.gameFilters {
  gap: 12px;
  display: flex;

  margin-top: 56px;
}

.gameMoreFilters {
  width: 138px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-left: 5px;
}

.gameMoreFilters:hover {
  background: #7f56d9;
  color: #ffffff;
}

.gameListButtons {
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.gameListButtons:hover {
  background: #7f56d9;
  color: #ffffff;
}

.gameListMiddle {
  margin-top: 23px;
  display: flex;
  gap: 24px;
}

.gameChoose {
  display: flex;
  flex-direction: column;

  gap: 6px;
}

.gameChoose > select {
  width: 320px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #667085;
  padding-left: 16px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameChoose > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.gameListSearch {
  display: flex;
  margin-top: 126px;
  justify-content: space-between;
}

.viewCalendar {
  width: 163px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  gap: 7px;
  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.gameSerach {
  position: absolute;
  left: 546px;
}

.gameSerach > input {
  width: 320px;
  height: 41px;
  padding-left: 32px;
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.searchIcon {
  position: absolute;
  padding-left: 16px;
  top: 14px;
}

.gameCalendarButtons {
  width: 49%;
  display: flex;
  gap: 12px;
}

.gameEditButton {
  width: 59px;
  height: 40px;

  background: #d6bbfb;

  border: 1px solid #d6bbfb;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameUnscheduleButton {
  width: 113px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameDeleteButton {
  width: 76px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #d6bbfb;
  color: #ffffff;

  background: #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameListFooter {
  border: 1px solid #eaecf0;
  margin-top: 37px;
  display: flex;
  flex-direction: column;
}

.gamaeListFooter {
  display: flex;

}

.gameListChoose {
  width: 150px;
  height: 44px;
  display: flex;
  align-items: center;
  border:1px solid #eaecf0;
}
.gameListChoose > span {
  margin-left: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.gameLisTableCheckBox {
  width: 150px;
  height: 72px;
  display: flex;
  border: 1px solid #eaecf0;
}

.gameLisTableContain {
  width: 150px;
  height: 44px;
  display: flex;
}
.gameLisTableContain > span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 24px;

  color: #101828;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.gameLisTableCheckBox > input {
  margin-left: 24px;
}

.gameListData {
  display: flex;
  flex-direction: column;
}

.gameListDataTable {
  display: flex;
}

.gameLisTableContain {
  width: 150px;
  height: 72px;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #101828;
}

.goodison {
  width: 150px;
  height: 72px;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #101828;
}

.gameListScrool {
}

.gameListScrool::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-y: scroll;

  width: 387px;
  height: 20px;
  left: 32px;
  top: 384px;

  background: #d6bbfb;
  border-radius: 8px;
}

.style1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.style1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.forceOverflow {
  min-height: 450px;
}

.gamePageNextPrev {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.gamePageination {
  display: flex;

  gap: 12px;
}

.gameButtonPrevius {
  width: 86px;
  height: 36px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gameButtonNext {
  width: 60px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.gamePageLength > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.gamePageBallances {

  display: flex;
  margin-top: 56px;
  gap: 24px;
}

.showButtonBallance {
  width: 135px;
  height: 40px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.printButton{
  width: 140px;
  height: 40px;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

