.coachModal {
  display: flex;
  flex-direction: column;
}

.titleCoach {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleCoac > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.iconGreenPlus {
  width: 48px;
  height: 48px;
  background: #d1fadf;
  border: 8px solid #ecfdf3;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullName {
  margin-top: 20px;
  display: flex;
  gap: 23px;
}

.firstName {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.firstName > label {
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.firstName > input {
  width: 320px;
  height: 40px;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.personRoles {
  margin-top: 20px;
}

.personRoles > span {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.coachModalCheckbox {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.coachCheck {
  display: flex;
  gap: 8px;
}

.coachCheck > span {
  font-size: 18px;
  line-height: 20px;
  color: #101828;
}

.coachCheck>select{
  width: 320px;
  height: 41px;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 14px;
  color: #667085;
  border: 2px solid #eaecf0;
  background-image: linear-gradient(45deg, transparent 50%, #667085 50%), linear-gradient(135deg, #667085 50%, transparent 50%), radial-gradient(transparent 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}



.coachModalForm {

  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.phone {
 
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.phone > input {
  padding: 10px 14px;
  width: 320px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.phone > label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}


.coachModalUpload{
 
    display: flex;
    justify-content: center;
    margin-top:32px ;
}

.uploadButton{
width: 280px  !important;
height: 44px !important;
font-weight: 600 !important;
font-size: 14px !important;
line-height: 20px !important;
color: #FFFFFF !important;
background: #D6BBFB !important;
border: 1px solid #D6BBFB !important;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
border-radius: 8px !important;
display: flex !important;
justify-content: center !important;
align-items: center !important;
gap:10px !important;


} 