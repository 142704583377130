@font-face {
    font-family: "Inter";
    src: url("./assets/font-family/Inter-Medium.ttf");
}
* {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}
 

