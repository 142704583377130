.divisionManage {
  width: 75%;
  height: 948px;
  background: white;
  border-radius: 40px 0px 0px 40px;
  padding: 32px 32px 48px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.headerManage {
  width: 95%;
  display: flex;
  height: 80px;
  margin-top: 15px;
  justify-content: space-between;
}

.manageTeamName {
  display: flex;
}

.manageTeamName > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;

  color: #101828;
}

.manageCV {
  display: flex;
  gap: 16px;
}

.buttonCV {
  width: 209px;
  height: 44px;
  background: #d6bbfb;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.buttonCV > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.manageEdit {
  width: 59px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d6bbfb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.manageView {
  width: 133px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.middleManage {
  margin-top: 36px;
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.middleManage > span {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.middleManage > button {
  width: 66px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.footerManage {
  margin-top: 36px;
  display: flex;
  
  gap: 24px;
  flex-wrap: wrap;
}










