.createNewTeamHeader{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.createIcon{
    width: 48px;
    height: 48px;
    display: flex;

    justify-content: center;
align-items: center;
    background: #D1FADF;


    border: 8px solid #ECFDF3;
    border-radius: 28px;
}

.createNewTeamHeader>span{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;

}

.createNewTeamMiddle{
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;

}

.teamName{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.teamName>input{
    padding: 10px 14px;

    width: 320px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.teamName>select{
    padding: 10px 14px;

    width: 320px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.createInputs{
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
    margin-top: 20px;

}

.createFooter{

    margin-top: 32px;
    width: 100%;
}

.createNewTeamFooter{

    display: flex;
    flex-direction: column;


}
.createUpload{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 234px;
    height: 44px;
    background: #D6BBFB;
    border: 1px solid #D6BBFB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

}

.createSize{
    display: flex;

    justify-content: center;
}

.saveCancle{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 44px;

}

.saveButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 325.5px;
    height: 44px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #7F56D9;
    border: 1px solid #7F56D9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}


.cancleButton{
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 325.5px;
    height: 44px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
